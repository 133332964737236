<template>
  <div>
    <section role="main" class="empty">
      <div class="container">
        <div class="row icon-tabs">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <router-link to="/executive" class="nav-link"
                ><img src="/img/icons/executives.png" alt="Executives" />
                Executives</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/company" class="nav-link"
                ><img src="/img/icons/companies.png" alt="Companies" />
                Companies</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link"
                ><img src="/img/icons/deals.png" alt="News" /> News</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/deals-transactions" class="nav-link"
                ><img src="/img/icons/deals.png" alt="Deals & Transactions" />
                Deals & Transactions</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/listing" class="nav-link"
                ><img src="/img/book.png" style="height: 23px" />
                Listing</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/commentaries" class="nav-link"
                ><img src="/img/icons/commentaries.png" alt="Commentaries" />
                Commentaries</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/interesting-bites" class="nav-link active"
                ><img
                  src="/img/icons/interesting.png"
                  alt="Interesting Bites"
                />
                Interesting Bites</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/open-positons-new" class="nav-link"
                ><img src="/img/icons/position.png" alt="Open Position" /> Open
                Positons</router-link
              >
            </li>
            <li class="">
              <a
                class="nav-link"
                style="
                  font-weight: bold !important;
                  margin-top: 8px;
                  font-size: 13px;
                  color: #1f3342;
                "
              >
                {{ count }}</a
              >
            </li>
          </ul>
          <div id="sixthmenu" class="tab-content mb-3">
            <div class="container px-0">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <!-- <form class="col-12 form-search form-inline"> -->
                  <input
                    type="text"
                    class="search-query"
                    style="border: 0px none; width: 100%"
                    v-model="searchObj.keyword"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search..."
                  />
                  <!-- </form> -->
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company</label>
                <Multiselect
                  mode="single"
                  trackBy="company_name"
                  label="company_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.company_id"
                  :options="companyList"
                  searchable="true"
                  v-on:change="geteComponydetail($event)"
                  @search-change="searchCompanyName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Industry</label>
                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.industry_id"
                  :options="industryList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchIndustryName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Select Topic</label>
                <Multiselect
                  mode="single"
                  trackBy="topic_name"
                  label="topic_name"
                  valueProp="id"
                  v-model="searchObj.topic_id"
                  :options="topicList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchTopicName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">From Date</label>
                <datepicker
                  v-model="searchObj.from_date"
                  inputFormat="dd/MM/yyyy"
                  placeholder="To Date"
                  class="form-control"
                  style="background-color: white !important; height: 35px"
                  :clearable="false"
                  :upperLimit="searchObj.to_date ? searchObj.to_date : null"
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">To Date</label>
                <datepicker
                  v-model="searchObj.to_date"
                  inputFormat="dd/MM/yyyy"
                  class="form-control"
                  placeholder="To Date"
                  :clearable="false"
                  style="background-color: white !important; height: 35px"
                  :lowerLimit="searchObj.from_date ? searchObj.from_date : null"
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <!-- <label></label> -->
                <button
                  type="button"
                  style="margin-top: 23px; width: 50%"
                  v-on:click="getList(0)"
                  class="apply-btn mr-1"
                >
                  Apply
                </button>
                <button
                  v-on:click="resetFilter()"
                  type="button"
                  style="margin-top: 23px; width: 40%"
                  class="apply-btn"
                >
                  Reset
                </button>
              </div>
            </div>
            <hr />
            <div class="row mt-4">
              <div class="col-lg-7 col-sm-8">
                <div v-for="(data, index) in list" :key="index">
                  <div class="row">
                    <div
                      v-if="data.is_company_logo == 1"
                      class="col-lg-2 col-sm-2 col-2 text-center"
                      style="cursor: pointer"
                      v-on:click="openLink(data.news_url)"
                    >
                      <img
                        :title="
                          data.company && data.company.company_name
                            ? data.company.company_name
                            : ''
                        "
                        :src="
                          data.company && data.company.company_logo
                            ? data.company.company_logo
                            : '/img/user-pic.png'
                        "
                        alt=""
                        class="md-img"
                      />
                      <br />
                      <span class="update-date">{{
                        $helperService.getFormatedDate(data.published_date)
                      }}</span>
                    </div>

                    <div
                      v-if="data.is_company_logo == 0"
                      class="col-lg-2 col-sm-2 col-2 text-center"
                      style="cursor: pointer"
                      v-on:click="openLink(data.news_url)"
                    >
                      <img
                        :title="
                          data.publication && data.publication.name
                            ? data.publication.name
                            : ''
                        "
                        :src="
                          data.publication && data.publication.publication_logo
                            ? data.publication.publication_logo
                            : ''
                        "
                        alt=""
                        class="md-img"
                      />
                      <br />
                      <span class="update-date">{{
                        $helperService.getFormatedDate(data.published_date)
                      }}</span>
                    </div>

                    <div class="col-lg-8 col-sm-8 col-7">
                      <div
                        style="font-weight: bold"
                        class="clrb"
                        v-if="data.bites.length <= 200"
                      >
                        {{ data.bites }}
                      </div>
                      <div
                        style="font-weight: bold"
                        class="clrb"
                        v-if="data.bites.length > 200"
                      >
                        {{ formattedBody(data) }}
                        <a
                          class="link cursor-pointer"
                          v-on:click="
                            data.showingFullText = !data.showingFullText
                          "
                        >
                          {{ data.showingFullText ? "Read less" : "Read more" }}
                        </a>
                      </div>
                    </div>
                    <!-- <div class="col-lg-2 col-sm-2 col-3 text-center">
                      <img
                        :src="
                          data.company && data.company.company_logo
                            ? data.company.company_logo
                            : '/img/user-pic.png'
                        "
                        alt=""
                        class="img-fluid"
                      />
                    </div> -->
                  </div>
                  <hr />
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>

              <div class="col-lg-5 col-sm-4">
                <canvas
                  id="bar-chart6"
                  width="200"
                  height="200"
                  class="mt-3 ml-3"
                >
                </canvas>
              </div>
            </div>

            <div class="row mx-0 mb-4">
              <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                  <Pagination
                    @page-change="pageChange"
                    @items-per-page-change="itemsPerPageChange"
                    ref="listPagination"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
import datepicker from "vue3-datepicker";
import moment from "moment";
export default {
  name: "News",
  components: {
    ErrorComponent,
    Pagination,
    Multiselect,
    datepicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      count: 0,
      searchObj: {
        type: "",
        from_date: "",
        to_date: "",
        industry_id: "",
        company_id: "",
        topic_id: "",
      },
      industryList: [],
      bitesMovesPerDay: [],
      company_ids: [],
      companyList: [],
      topicList: [],
      offset: -1,
      list: [],
      companyKeyword: "",
      industryKeyword: "",
      topicKeyword: "",
    };
  },
  mounted() {
    if (this.id) {
      this.getExecutiveDetail(this.id);
    } else {
      this.getList(0);
      this.companylist();
    }
    this.industrylist();
    this.topiclist();
    this.getbitesMoveByYear();
  },
  methods: {
    resetFilter() {
      this.searchObj = {};
      this.searchObj.type = "";
      this.searchObj.keyword = "";
      this.searchObj.from_date = "";
      this.searchObj.to_date = "";
      this.searchObj.industry_id = "";
      this.searchObj.job_function_id_new = "";
      this.searchObj.topic_id = "";
      this.searchObj.company_id = "";

      this.getList(0);
    },
    formattedBody(data) {
      if (data.showingFullText) {
        return data.bites;
      }
      return `${data.bites.slice(0, 200).trim()}...`;
    },
    getExecutiveDetail(id) {
      if (id) {
        this.searchObj.company_id = id;
        this.company_ids.push(id);
      }
      this.companylist();
      this.getList(0);
    },
    getbitesMoveByYear() {
      var obj = {};
      this.bitesMovesPerDay = [];
      // if (this.searchObj.from_date) {
      //   obj.from_date = moment(this.searchObj.from_date).format("DD-MM-YYYY");
      // }
      // if (this.searchObj.to_date) {
      //   obj.to_date = moment(this.searchObj.to_date).format("DD-MM-YYYY");
      // }
      this.$api
        .getAPI({
          _action: "/bites/moves/year",
          _body: obj,
        })
        .then((res) => {
          this.bitesMovesPerDay = res;
          this.getGraphBitesMoveByDay();
        })
        .catch(() => {});
    },
    openLink(url) {
      window.open(url);
    },
    getGraphBitesMoveByDay() {
      this.labelsArr = [];
      this.revanueArr = [];

      for (var i = 0; i < this.bitesMovesPerDay.length; i++) {
        var topic_name = this.bitesMovesPerDay[i].topic_name;
        this.labelsArr.push(topic_name);
        var revanue = this.bitesMovesPerDay[i].count;
        this.revanueArr.push(revanue);
      }
      if (this.sales) {
        this.sales.destroy();
      }
      const ctx = document.getElementById("bar-chart6").getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: this.labelsArr,
          datasets: [
            {
              data: this.revanueArr,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(235, 52, 235)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(72, 245, 66)",
              ],
              borderColor: "#87CEEB",
              borderWidth: 0,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              display: false,
            },
          },
        },
      });
    },
    searchTopicName(e) {
      this.topicKeyword = e;
      this.topiclist();
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.industrylist();
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
        })
        .catch(() => {});
    },
    topiclist() {
      var obj = {};
      if (this.topicKeyword) {
        obj.keyword = this.topicKeyword;
      }
      this.$api
        .getAPI({
          _action: "/topic/list",
          _body: obj,
        })
        .then((res) => {
          this.topicList = res;
        })
        .catch(() => {});
    },
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    geteComponydetail(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "/company/detail/" + id,
          })

          .then((res) => {
            this.searchObj.industry_id = res.industry_id;
          })
          .catch(() => {});
      }
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    ClearFilter() {
      this.searchObj = {
        type: "",
        from_date: "",
        to_date: "",
        industry_id: "",
        company_id: "",
      };
      this.getList(0);
    },
    getList(page) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      var obj = {};
      obj.offset = this.searchObj.offset;
      if (this.searchObj.from_date) {
        obj.from_date = moment(this.searchObj.from_date).format("DD-MM-YYYY");
      }
      if (this.searchObj.to_date) {
        obj.to_date = moment(this.searchObj.to_date).format("DD-MM-YYYY");
      }
      if (this.searchObj.topic_id) {
        obj.topic_id = this.searchObj.topic_id;
      }
      if (this.searchObj.industry_id) {
        obj.industry_id = this.searchObj.industry_id;
      }
      if (this.searchObj.company_id) {
        obj.company_id = this.searchObj.company_id;
      }
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      }
      this.$api
        .getAPI({
          _action: "/interesting-bites/pagination/list",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.count = res.count;
          for (var i in res.list) {
            res.list[i].showingFullText = false;
          }
          this.offset = res.next_offset;
          this.getbitesMoveByYear();
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count,10);
              } else {
                this.$refs.listPagination.setTotalCount(0,10);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No Interesting Bites list available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 35px;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
